.app-to-step-3 {
    align-self: flex-end;
}

@media screen and (max-width: 1024px) {
  .load-photo-popup {
    width: 75%;
  }
}

@media screen and (max-width: 768px) {
  
  .d-none-sm-down {
    display: none;
  }
  
  .text-md-down-center {
    text-align: center;
  }

  .btn-decrease-font {
    font-size: 0.9em !important;
  }

  .load-photo-popup {
    width: 95%;
    padding: 45px 22px;
  }

  .uploaded-photo {
    max-height: 70%;
  }
  
}
@media screen and (max-width: 767px) {
  .d-none-on-md {
    display: none;
  }
}

.more-options-on-md {
  max-width: 214px;
  display: block;
  margin: 0 auto;
  padding: 20px 51px 20px 18px;
}

@media screen and (min-width: 768px) {
  .more-options-on-md {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .uploaded-photo {
    max-height: 56%;
  }

  .progress-loader {
    bottom: 15%;
  }

  .final-info {
    .title-md.thankyou-title {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .gallery-item {
      max-height: 265px;
      max-width: 265px;
      margin: 0 auto;
    }
    .person-name {
      font-size: 22px;
      margin-bottom: 8px;
      margin-top: 10px;
    }
  }
  

  .progress-loader {
    position: relative;
    margin-bottom: 5%;
    transform: none;
    margin: 0 auto;
    left: 0;
  }

  .smile-measure-btns {
    display: flex;
    position: relative;
    width: 100%;
  }

  .change-photo-btn, .upload-photo-btn {
    bottom: 0;
    position: relative;
    left: 0;
    right: 0;
  }

}

@media screen and (max-width: 375px) {
  .app-to-step-3 {
    align-self: center;
  }

  .game-slide {
    .title-md {
      font-size: 28px;
    }

    .regular-text {
      font-size: 16px;
    }
  }

  /* .uploaded-photo {
    height: 50%;
  }
 */
  .progress-loader {
    bottom: 40%;
    max-height: 154px;
  }

  .temp-font-xlg {
    font-size: 32px;
  }

  .smile-measure-btns {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 38%;

    a {
      display: block;

      &:first-child {
        order: 2;
      }
    }

    .change-photo-btn {
      position: relative;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      padding-left: 0;
      bottom: 0;

      img {
        position: relative;
        margin-right: 15px;
      }
    }

    .upload-photo-btn {
      position: relative;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      padding-left: 0;
      bottom: 0;
      padding: 13px 0px 13px 0px;
      margin-bottom: 16px;

      img {
        position: relative;
        margin-left: 15px;
        right: 0;
      }
    }
  }

  .app-contact-input {
    padding: 8px;
  }

  .app-label {
    padding: 18px 0 5px 0;
  }
  
  .chcks-mandatory {
    font-size: 12px;
  }

  .name-font-xlg {
    font-size: 28px;
  }

  .js-slide-4 {
    padding: 0 !important;
  }
}