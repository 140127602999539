$font-family-primary: 'Open Sans', sans-serif;
$light-silver: #fafafa;

$hover-transition-time: 0.3s;
$slide-time: 1s;

$skew-1: 4deg;
$skew-2: 2deg;
$skew-2-sm: 11deg;

$skew-3:  2deg;
$skew-3-sm:  8deg;

$skew-4: 4deg;

$skew-5: 7deg;
$skew-5-sm: 14deg;