.draw-dog-wrapper {
    width: 540px;
    height: 482px;
    background-color: #ffffff;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.draw-inner-green {
    height: 294px;
    width: 410px;
    background-image: linear-gradient(55deg, #9fc162, #8dae65);
    position: relative;

    img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.draw-step-1 {
    .title-lg {
        font-size: 40px;
        padding-top: 100px;
        margin-bottom: 45px;
    }
    .btn-orange {
        font-size: 24px;
        font-weight: bold;
        line-height: normal;
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;
        padding: 20px;
        width: 410px;
        margin-top: 20px;
    }
}
.draw-step-2 {
    height: 100%;
    display: flex;
    align-items: center;

    .title-lg {
        font-size: 40px;
        font-weight: normal;
        line-height: normal;
        text-align: center;
        color: #515151;
        padding-top: 80px;
        padding-bottom: 160px;
    }

    .draw-dog-wrapper {
        height: auto;
    }
}
.draw-step-3 {
    height: 100%;
    display: flex;
    align-items: center;

    .title-lg {
        font-size: 50px;
        font-weight: bold;
        line-height: normal;
        text-align: center;
        color: #515151;
        padding-top: 200px;
        padding-bottom: 72px;
    }

    .person-name {
        font-size: 50px;
        font-weight: bold;
        line-height: normal;
        color: #e98400;
        text-shadow: none;
        padding: 81px 0 200px 0;
    }

    .draw-dog-wrapper {
        height: auto;
    }
}
.drawing-progress-wrapper {
    height: 40px;
    width: 406px;
    border: 3px solid #e98400;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 28px;
    margin-bottom: 85px;

    .drawing-progress-animated {
        height: 100%;
        width: 0;
        background-color: #e98400;
        transition: 4s;
        transition: 4s linear;
    }
}

.drawing-logo {
    border-radius: 0;
    align-self: start;
    padding-top: 50px;
}

.skew-bg-1 {
    position: absolute;
    height: 100%;
    background-image: radial-gradient(circle at 9% 0, #009ad9, #0978a6);
    width: 100%;
    transform: skewY(-22deg);
    transform-origin: top left;
    bottom: 18%;
}

.drawing-bg-general {
    background-image: linear-gradient(to right, #9fc162, #8dae65);
}

.drawing-sun {
    img {
        transform-origin: 50% 0%;
    }
}

.draw-step-2, .draw-step-3 {
    display: none;
}

.skew-bg-1 {
    -webkit-transition: background-image 0.6s ease-in-out;
    transition: background-image 0.6s ease-in-out;
}